import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http:HttpClient) { }

  public loginSSO(clientId:string, redirectUri:string, production = false) {
    const envURL = production ? "login" : "login-stg";
    window.location.href = `https://${envURL}.pwc.com/openam/oauth2/authorize?response_type=code&state=txty&client_id=${clientId}&scope=${environment.identity.scope}&redirect_uri=${redirectUri}`
  }

  public async loginBackend(code: string) {
    await firstValueFrom(this.http.get(`/api/login/callback?code=${code}`));
  }

  public async logoutSSO() {
    await firstValueFrom(this.http.get("/api/login/logout"));
  }

  public user_data() {
    return this.http.get("/api/login/user")
  }
}
