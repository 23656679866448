import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, share, Subject} from 'rxjs';

@Injectable()
export class ProgressBarHttpInterceptor implements HttpInterceptor {

  public static obs: Subject<number> = new Subject<number>()
  private count = 0;

  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(req.headers.has("Ignore-Progress")) {
      return next.handle(req)
    }

    this.changeCount(1)
    const observable = next.handle(req).pipe(share());
    observable.subscribe({
      complete: () => this.changeCount(-1),
      error: () => this.changeCount(-1)
    })
    return observable;
  }

  private changeCount(number: number) {
    this.count += number;
    ProgressBarHttpInterceptor.obs.next(this.count)
  }
}
