import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { WebidService } from '../services/webid.service';
import { identuser } from '../interfaces/identuser';
import { DatePipe } from '@angular/common';
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  providers: [WebidService],
})
export class VideoComponent implements OnInit{

  ngOnInit() {
    this.updateButtonState()
  }

  constructor(
    public router: Router,
    private webidservice: WebidService,
    private authService: AuthService,
    public datepipe: DatePipe
  ) {}

  dateOfBirthArray: Date[] = [];
  onSelectedDatesChange(event: any) {
    this.updateButtonState()
  }
  //Später: datepipe.transform(this.dateBirth, 'yyyy-MM-dd')

  checkboxSelected = true;
  checkboxDisabled = false;
  showCheckboxLabel = true;
  tempState = false;
  isSubmitButtonEnabled = false;

  list = [
    { name: 'General Terms and Conditions', key: 'A', selected: true },
    { name: 'Privacy Policy', key: 'M', selected: true },
    { name: 'Declaration of Consent', key: 'P', selected: true },
  ];

  updateButtonState() {
    // Check if all checkboxes are selected
    this.isSubmitButtonEnabled = !this.list.every((item) => item.selected)
      || this.dateOfBirthArray.length <= 0
      || !this.person.user.contact.email
      || !this.person.user.firstname
      || !this.person.user.lastname
  }

  person: identuser = {
    transactionId: '68J49',
    actionType: 'cert',
    identMode: 'video_ident',
    termsAndConditionsConfirmed: true,
    //"verimiTermsAndConditionsConfirmed": true,
    user: {
      firstname: localStorage.getItem('first_name') || '',
      lastname: localStorage.getItem('family_name') || '',
      //sex:'',
      dateOfBirth: '', //this.datepipe.transform(this.dateOfBirthArray[0], 'yyyy-MM-dd'),
      //address: { country: '', zip:'', city:''},
      contact: { email: localStorage.getItem('preferredMail') || '' },
    },
  };

  openLink(url: string) {
    window.location.href = url;
  }

  StartCall() {
    this.person.user.dateOfBirth = this.datepipe.transform(
      this.dateOfBirthArray[0],
      'yyyy-MM-dd'
    );
    this.webidservice.webIdRequest(this.person).subscribe(
      (response) => {
        const data = JSON.parse(JSON.stringify(response)); //Direkt response.url hat eine Fehlermeldung gegeben
        this.openLink(data.url);
      },
      (error) => console.log(error)
    );
  }

  async logout() {
    await this.authService.logoutSSO();
    await this.router.navigateByUrl("")
  }
}
