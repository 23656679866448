<div class="box">
  <img
    src="/assets/PwC_Outline_Logo_White.png"
    alt="image"
    style="width: 150px; margin-left: -20px"
  />

  <h1>Welcome to PwC Video Id {{version}}</h1>
  On this page, you need to provide the required data to initiate the
  identification process. Afterward, a video call will be initiated with a WebID
  employee to authenticate the authenticity of your documents.

  <ap-button
    [btnType]="'tertiary'"
    [label]="'Login'"
    (click)="login()"
    [style]="'padding:10px; margin-top:15px; width:100%;'"
  ></ap-button>
</div>
