import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import {map, Observable, of} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from "../services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  public canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const accessToken = localStorage.getItem('access_token');

    return this.authService.user_data().pipe(
      map(response => {
        const data = JSON.parse(JSON.stringify(response));
        localStorage.setItem('family_name', data.family_name);
        localStorage.setItem('first_name', data.given_name);
        localStorage.setItem('preferredMail', data.preferredMail);
        return true
      }),
      catchError((err, caught) => {
        return of(this.router.createUrlTree(["auth/login"]))
      })
    )
  }
}
