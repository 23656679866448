import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { identuser } from '../interfaces/identuser';
import { environment } from 'src/environments/environment';
import {of} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class WebidService {
  constructor(private http: HttpClient) {}



  webIdRequest(data: identuser) {
    return this.http.post<any>("api/url", data)
  }
}
