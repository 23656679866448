<!--
  <header>
  <ap-header [type]="'light'">
    <ng-template ngTemplate="title">
          Video Identification
    </ng-template>

  <ng-template ngTemplate="content">
    <ap-header-options-item [iconName]="'search-outline'" [label]="'Search'">
    </ap-header-options-item>
  </ng-template>
  <ng-template ngTemplate="options">
    <ap-header-options-item [iconName]="'help-question-outline'" [label]="'Support'">
    </ap-header-options-item>
    <ap-header-options-item [iconName]="'notification-outline'" [label]="'Alerts'">
    </ap-header-options-item>
  </ng-template>

    <ng-template ngTemplate="user">
      <ap-avatar name="TG" borderWidth="0" diameter="40" [role]="'button'"></ap-avatar>
    </ng-template>

  </ap-header>
</header>
-->

<div class="site-container"
  [ngStyle]="{ backgroundImage: 'url(./assets/PwC_Background_Pattern.svg)' }"
  style="background-size: cover; background-repeat: no-repeat; background-position: center"
>
<div class="http-progress">
  <app-progress [progressColor]="'#415385'" [height]="'0.3rem'" [progress]="progress" [class]="{'animated': progressBarAnimated}"></app-progress>
</div>
<router-outlet></router-outlet>


  <!-- <img src="assets\PwC_Geom.png" alt="Image" style="width:150%; height:150%;"> -->
</div>

<footer>
  <ap-footer
    [content]="footerContent"
    [type]="footerType"
    [links]="footerLinks"
    class="footer"
    [style]="
      'background-color: #e8e8e8; position:fixed; bottom:0px; width:100%;  padding:5px;'
    "
  ></ap-footer>
</footer>
