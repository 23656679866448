<ap-header type="solid">
  <ng-template ngTemplate="title">
    Video Identity
  </ng-template>
  <ng-template ngTemplate="options">
    <ap-header-options-item [iconName]="'logout-fill'" [label]="'Logout'" (onClick)="logout()"></ap-header-options-item>
  </ng-template>
</ap-header>

<div class="box">
  <h1>Personal Data</h1>
  <ap-field [title]="'E-mail'" [required]="true">
    <input appkit-field [(ngModel)]="person.user.contact.email" (ngModelChange)="updateButtonState()" />
    <ap-field-cleaner [(ngModel)]="person.user.contact.email" (ngModelChange)="updateButtonState()"></ap-field-cleaner>
  </ap-field>

  <ap-field [title]="'First Name'" [required]="true" style="padding-top: 5px">
    <input appkit-field [(ngModel)]="person.user.firstname" (ngModelChange)="updateButtonState()"/>
    <ap-field-cleaner [(ngModel)]="person.user.firstname" (ngModelChange)="updateButtonState()"></ap-field-cleaner>
  </ap-field>

  <ap-field [title]="'Last Name'" [required]="true" style="padding-top: 5px">
    <input appkit-field [(ngModel)]="person.user.lastname" (ngModelChange)="updateButtonState()" />
    <ap-field-cleaner [(ngModel)]="person.user.lastname" (ngModelChange)="updateButtonState()"></ap-field-cleaner>
  </ap-field>


<!--
<ap-dropdown [list]="dropdownGender" [selectType]="'single'" [title]="'Gender'" [(ngModel)]="person.user.sex" style="padding-top:5px"
              (onSelect)="onSelectDropdown($event)" [required]="true">
              <ap-dropdown-list-item #dropdownListItem *ngFor="let item of dropdownGender" [item]="item"
                (onSelectItem)="onSelectItem($event)"></ap-dropdown-list-item>
</ap-dropdown>
-->

  <ap-field
    [title]="'Date of birth'"
    [datepicker]="true"
    [dpType]="'single'"
    [dpRange]="false"
    style="padding-top: 5px"
    [dpDateformat]="'dd/MM/yyyy'"
    [(dpSelectedDates)]="dateOfBirthArray"
    [required]="true"
    (dpSelectedDatesChange)="onSelectedDatesChange($event)"
    class="custom-checkbox"
  >
    <input appkit-field />
  </ap-field>

  <ul style="background-color: white; padding: 5px; padding-top: 2px">
    <h1 style="color: black">Agree to Terms & Conditions</h1>
    <li
      *ngFor="let item of list"
      [ngStyle]="{ 'list-style-type': 'none' }"
    >
      <ap-checkbox
        [(ngModel)]="item.selected"
        [disabled]="checkboxDisabled"
        [indeterminate]="tempState"
        (ngModelChange)="updateButtonState()"
      >
        <span *ngIf="showCheckboxLabel">{{ item.name }}</span>
      </ap-checkbox>
    </li>
  </ul>

  <!--
  <h3>Adresse</h3>
  <ap-field [title]="'Land'" [required]="true">
      <input appkit-field [(ngModel)]="country" />
      <ap-field-cleaner [(ngModel)]="country"></ap-field-cleaner>
    </ap-field>
    test
    <ap-field [title]="'Stadt'" [required]="true">
      <input appkit-field [(ngModel)]="city" />
      <ap-field-cleaner [(ngModel)]="city"></ap-field-cleaner>
    </ap-field>

    <ap-field [title]="'Zip'" [required]="true">
      <input appkit-field [(ngModel)]="zip" />
      <ap-field-cleaner [(ngModel)]="zip"></ap-field-cleaner>
    </ap-field>
    -->

  <ap-button
    [btnType]="'tertiary'"
    (click)="StartCall()"
    [label]="'Start Process'"
    [style]="'padding:10px; margin-top:15px; width:100%;'"
    [disabled]="isSubmitButtonEnabled"
  ></ap-button>
</div>
