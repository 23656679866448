import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent {
  @Input() progress : number = 0;
  @Input() height: string = "";
  @Input() bgColor: string = "";
  @Input() progressColor: string = ""
  @Input() progressText: string = ""
  @Input() progressTextColor: string = ""

  _height() {
    return this.height !== "" ? this.height : "0.25rem"
  }

  _bgColor() {
    return this.bgColor !== "" ? this.bgColor : "#415385"
  }

  _progressColor() {
    return this.progressColor !== "" ? this.progressColor : "#21812d"
  }

  _progressTextColor() {
    return this.progressTextColor !== "" ? this.progressTextColor : "#000"
  }
}
