import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import packageJson from "../../../package.json"
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public title: string = 'webid';
  public isRunningAuthentication?: Observable<boolean>;
  public isAuthenticated?: Observable<boolean>;
  public isLoggedOut?: Observable<boolean>;
  public version = packageJson.version

  constructor(private auth: AuthService) {}

  login() {
    this.auth.loginSSO(environment.identity.client_app_id, environment.identity.redirect_uri, environment.production)
  }
}
