import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

//Components import:
import { LoginComponent } from './login/login.component';
import { VideoComponent } from './video/video.component';
import { WebidService } from './services/webid.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ProgressComponent} from "./progress/progress.component";
//import { environment } from 'src/environments/environment';

//Appkit import:
import { HeaderModule } from '@appkit4/angular-components/header';
import { AvatarModule } from '@appkit4/angular-components/avatar';
import { FooterModule } from '@appkit4/angular-components/footer';
import { PanelModule } from '@appkit4/angular-components/panel';
import { ButtonModule } from '@appkit4/angular-components/button';
import { FieldModule } from '@appkit4/angular-components/field';
import { DropdownModule } from '@appkit4/angular-components/dropdown';
import { CheckboxModule } from '@appkit4/angular-components/checkbox';
import { ToggleModule } from '@appkit4/angular-components/toggle';
import { LoadingModule} from "@appkit4/angular-components/loading";
//import { CalendarModule } from "@appkit4/angular-components/calendar";

import { AuthGuard } from './guards/auth.guard';
import {ProgressBarHttpInterceptor} from "./progress-bar-http-interceptor";

@NgModule({
  declarations: [AppComponent, LoginComponent, VideoComponent, ProgressComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HeaderModule,
    AvatarModule,
    FooterModule,
    PanelModule,
    ButtonModule,
    HttpClientModule,
    FieldModule,
    FormsModule,
    DropdownModule,
    CheckboxModule,
    ToggleModule,
    LoadingModule
  ],
  providers: [
    DatePipe, //For Date conversion to string in specific format
    WebidService,
    AuthGuard,
    {provide: HTTP_INTERCEPTORS, useClass: ProgressBarHttpInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
