import { IEnvironment } from 'src/app/interfaces/environment.interface';

export const environment: IEnvironment = {
  production: false,
  //redirectUrl: 'https://stage-pwc-webid.azurewebsites.net/Video',
  identity: {
    authorization_endpoint: 'https://login-stg.pwc.com/openam/oauth2/authorize',
    client_app_id: 'urn:webid:video:Identity:AT:stage',
    access_endpoint: 'https://login-stg.pwc.com/openam/oauth2/access_token',
    user_info_endpoint: 'https://login-stg.pwc.com/openam/oauth2/userinfo',
    redirect_uri: 'https://stage.videoidentity.pwc.at/',
    refresh: {
      session_endpoint:
        'https://login-stg.pwc.com:443/openam/oauth2/connect/checkSession',
    },
    scope: 'profile+openid',
  },
};
