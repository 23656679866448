import {Component, OnDestroy, OnInit} from '@angular/core';
import { AccessTokenResponse } from './interfaces/access-token-response.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import {firstValueFrom, Subscription} from "rxjs";
import {AuthService} from "./services/auth.service";
import {ProgressBarHttpInterceptor} from "./progress-bar-http-interceptor";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'webidangular';

  footerContent: string = '© 2024 PwC. All rights reserved.';
  footerType: string = 'links';
  footerLinks: any[] = [
    {
      name: 'Privacy policy',
      href: 'https://www.pwc.at/de/legal-disclaimer/datenschutzerklaerung-new.html',
      target: '_blank',
    },
    {
      name: 'Terms and conditions',
      href: 'https://webid-solutions.de/general-terms-and-conditions/?lang=en',
      target: '_blank',
    },
    {
      name: 'Cookie notice',
      href: 'https://www.pwc.at/de/legal-disclaimer/cookie-information.html',
      target: '_blank'
    },
    {
      name: 'Legal notice',
      href: 'https://www.pwc.at/de/impressum.html',
      target: '_blank'
    }
  ];

  constructor(private router: Router, private auth: AuthService) {}

  private httpProgressSubscription: Subscription | undefined;
  progress: number = 0;
  progressBarAnimated: boolean = true

  async ngOnInit(): Promise<void> {
    if (window.location.search.startsWith('?code')) {
      const search: string = window.location.search.slice(1);
      const params = JSON.parse(
        `{"${decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      );

      try {
        await this.auth.loginBackend(params.code);
        await this.router.navigateByUrl("video")
      } catch (e: any) {
      }
    }

    this.httpProgressSubscription = ProgressBarHttpInterceptor.obs.subscribe(
      count => setTimeout(() => {
        if(count > 0) {
          this.progressBarAnimated = false;
          this.progress = 0;

          setTimeout(() => {
            this.progressBarAnimated = true;
            this.progress = 60
          }, 200)

        } else {
          setTimeout(() => {
            this.progress = 100
            // TODO: REFACTOR in thenable
            setTimeout(() => {
              this.progressBarAnimated = false;
              this.progress = 0;
            },600)
          }, 200)
        }
      }))
  }

  ngOnDestroy() {
    this.httpProgressSubscription?.unsubscribe()
  }
}
